<template>
  <div class="warp-content">
    <el-form>
      <h2>
        <i class="vertcial-strip"></i>
        关注按钮设置
      </h2>
      <div class="flex-box el-form-item">
        <span class="choice-color">
          <span>按钮背景颜色</span>
          <el-color-picker
            v-model="wData.props.buttonBackgroundColor"
          ></el-color-picker>
        </span>
        <span class="choice-color">
          <span>按钮字体颜色</span>
          <el-color-picker
            v-model="wData.props.buttonFontColor"
          ></el-color-picker>
        </span>
      </div>
      <div class="inine-set el-form-item">
        字体设置：
        <el-select
          v-model="wData.props.buttonFont"
          placeholder="字体大小"
          style="width: 120px"
        >
          <el-option
            v-for="(item, key) in fontSizeMap"
            :key="key"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </div>
      <div class="el-form-item" style="width: 50%; padding-left: 10px">
        按钮圆角
        <el-slider v-model="wData.props.buttonFillet"></el-slider>
      </div>
      <div class="el-form-item flex-box">
        <div class="flex-box number-input">
          <div>高度：</div>
          <el-input-number
            v-model="wData.props.buttonHeight"
            controls-position="right"
            :precision="2"
          ></el-input-number>
        </div>
        <div class="flex-box number-input">
          <div>宽度：</div>
          <el-input-number
            v-model="wData.props.buttonWidth"
            controls-position="right"
            :precision="2"
          ></el-input-number>
        </div>
      </div>

      <h2>
        <i class="vertcial-strip"></i>
        微信内容设置
      </h2>
      <el-form-item label="微信号码"
        ><el-input v-model="wData.props.wechatNumber"></el-input
      ></el-form-item>
      <el-form-item v-if="wData.type == 'click-copy'" label="按钮内容"
        ><el-input v-model="wData.props.buttonContent"></el-input
      ></el-form-item>
      <h2 v-if="wData.props.popupType == '2'">
        <i class="vertcial-strip"></i>
        头像设置
      </h2>
      <el-form-item v-if="wData.props.popupType == '2'" label="头像上传">
        <el-button type="text" @click="drawer = true">添加图片</el-button>
        <el-drawer :visible.sync="drawer" :with-header="false">
          <!-- <select-img
            v-model="wData.prop.src"
            :img-type="sendType"
            @imgSrcSel="imgSrcSel"
          ></select-img> -->
        </el-drawer>
      </el-form-item>
      <h2>
        <i class="vertcial-strip"></i>
        弹窗类型设置
      </h2>
      <el-form-item>
        <el-select
          v-model="wData.props.popupType"
          placeholder="请选择"
          style="width: 200px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import popupConfig from "../popupConfig.js";

export default {
  props: {
    wData: {
      type: Object,
      default() {
        return {
          id: "",
          name: "未命名",
          type: "wxCopy",
          props: {
            buttonBackgroundColor: "#2479E9",
            buttonFontColor: "#ffffff",
            buttonFont: "14px",
            buttonFillet: "20",
            buttonHeight: "25",
            buttonWidth: "50",
            wechatNumber: "默认微信",
            buttonContent: "",
            popupType: 1,
          },
        };
      },
    },
  },
  data() {
    return {
      drawer: false,
      sendType: "img",
      options: [...popupConfig],
      fontSizeMap: ["14px", "16px", "18px", "1rem", "2rem", "2.5rem"],
    };
  },
  methods: {
    imgSrcSel(meg) {
      console.log(meg);
      if (meg.length > 0) this.$props.wData.prop.src = meg[0];
      else
        this.$props.wData.prop.src =
          "http://240ps.com/jc/Dfile/20160907/j1609072_2.jpg";
    },
  },
};
</script>

<style scoped>
.flex-box {
  display: flex;
  align-items: center;
}

h2 {
  font-size: 1.2rem;
}
.el-input,
.el-textarea {
  width: 80%;
}
.inine-set {
  display: inline-block;
  padding: 5px 10px;
  box-sizing: border-box;
  margin-right: 1.3rem;
}
.el-color-picker {
  position: relative;
  top: 0.9rem;
  left: 1rem;
}
.vertcial-strip {
  display: inline-block;
  width: 5px;
  height: 20px;
  background-color: rgb(78, 144, 255);
  border-radius: 2rem;
  margin-right: 1rem;
  position: relative;
  top: 3px;
}
.input-box-title {
  position: relative;
  padding: 3px 12px;
  display: inline-block;
}

.choice-color {
  padding: 0 15px 10px 10px;
}

.number-input {
  margin: 0 5px;
}
.warp-content /deep/ .el-form-item__label {
  color: #000;
  text-indent: 10px;
  font-size: 15px;
}
</style>
