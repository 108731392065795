var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"drawbox"},[_c('el-drawer',{attrs:{"visible":_vm.dialog,"direction":_vm.direction,"show-close":false,"with-header":false,"before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialog=$event}}},[_c('div',[_c('el-tabs',{on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"图片选择","name":"1"}},[_c('div',{staticClass:"photoList"},_vm._l((_vm.pictureList),function(item,index){return _c('div',{key:index,class:_vm.clickActive === index ||
                (_vm.wData.type === 'picture-carousel' &&
                  _vm.wData.props.photoList.findIndex(
                    function (i) { return i.rotationId == item.id; }
                  ) > -1)
                  ? 'photoList_item photoList_item_active'
                  : 'photoList_item',on:{"click":function($event){return _vm.changeActive(item, index)}}},[_c('img',{attrs:{"src":item.photoUrl,"alt":""}}),_c('i',{staticClass:"el-icon-error isdelete",on:{"click":function($event){return _vm.deleteThis(item.id)}}}),_c('span',{class:_vm.clickActive === index
                    ? 'isselect isselect_active'
                    : 'isselect'},[_c('i',{staticClass:"el-icon-check"})])])}),0)]),_c('el-tab-pane',{attrs:{"label":"图片上传","name":"2"}},[_c('div',{staticClass:"setsty"},[_c('el-upload',{staticClass:"upload-demo uploadsty",attrs:{"action":"#","show-file-list":false,"http-request":_vm.upload}},[(_vm.dictAddForm.photoUrl)?_c('el-image',{staticClass:"upload-img",attrs:{"src":_vm.dictAddForm.photoUrl},on:{"click":_vm.deleteThisImg}}):_c('div',[_c('i',{staticClass:"el-icon-upload"}),_c('div',{staticClass:"el-upload__text"},[_vm._v(" 将文件拖到此处，或"),_c('em',[_vm._v("点击上传")])])])],1)],1),_c('div',{staticClass:"uploadBtn"},[(_vm.dictAddForm.photoUrl)?_c('el-button',{attrs:{"type":"primary","plain":""},on:{"click":_vm.addpic}},[_vm._v("上传")]):_vm._e()],1)])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }