<template>
  <div class="warp-content">
    <el-form>
      <h2><i class="vertcial-strip"></i>微信内容设置</h2>
      <el-form-item label="微信号码">
        <el-input v-model="wData.props.wechatNumber"></el-input>
      </el-form-item>
      <el-form-item label="内容描述">
        <el-input v-model="wData.props.content" type="textarea"></el-input>
      </el-form-item>
      <el-form-item>
        <div class="inine-set">
          基础字体设置：
          <el-select
            v-model="wData.props.basicFont"
            placeholder="字体大小"
            style="width: 150px"
          >
            <el-option
              v-for="(item, key) in fontSizeMap"
              :key="key"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <div class="inine-set">
          字体颜色：
          <el-color-picker
            v-model="wData.props.basicFontColor"
          ></el-color-picker>
        </div>
        <div class="inine-set">
          背景颜色：
          <el-color-picker
            v-model="wData.props.basicFontBackgroundColor"
          ></el-color-picker>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="inine-set">
          微信文案样式设置：
          <el-select
            v-model="wData.props.copywritingFont"
            placeholder="字体大小"
            style="width: 150px"
          >
            <el-option
              v-for="(item, key) in fontSizeMap"
              :key="key"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="inine-set">
          微信账号字体设置：
          <el-select
            v-model="wData.props.wechatNumberFont"
            placeholder="字体大小"
            style="width: 150px"
          >
            <el-option
              v-for="(item, key) in fontSizeMap"
              :key="key"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <div class="inine-set">
          微信账号字体颜色：
          <el-color-picker
            v-model="wData.props.wechatNumberColor"
          ></el-color-picker>
        </div>
      </el-form-item>
      <h2><i class="vertcial-strip"></i>布局设置</h2>
      <el-form-item>
        <span class="input-box-title">位置</span>
        <div class="inine-set">
          横轴：
          <el-input-number
            v-model="wData.props.horizontalAxis"
            controls-position="right"
            :precision="2"
          ></el-input-number>
        </div>
        <div class="inine-set">
          纵轴：
          <el-input-number
            v-model="wData.props.longitudinalAxis"
            controls-position="right"
            :precision="2"
          ></el-input-number>
        </div>
      </el-form-item>
      <el-form-item>
        <span class="input-box-title">尺寸</span>
        <div class="inine-set">
          高度：
          <el-input-number
            v-model="wData.props.height"
            controls-position="right"
            :precision="2"
          ></el-input-number>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    wData: {
      type: Object,
      default() {
        return {
          id: "",
          type: "wxCopy",
          props: {
            wechatNumber: "weixin帐号",
            content: "长按复制微信号，去微信搜索添加",
            basicFont: "",
            basicFontColor: "",
            basicFontBackgroundColor: "",
            wechatNumberFont: "",
            wechatNumberColor: "",
            copywritingFont: "",
            horizontalAxis: "",
            longitudinalAxis: "",
            height: "",
          },
        };
      },
    },
  },
  data() {
    return {
      fontSizeMap: ["14px", "16px", "18px", "1rem", "2rem", "2.5rem"],
    };
  },
  methods: {},
};
</script>

<style scoped>
h2 {
  font-size: 1.2rem;
}
.el-input,
.el-textarea {
  width: 80%;
}
.inine-set {
  /* display: inline-block; */
  padding: 5px 10px;
  box-sizing: border-box;
  margin-right: 1.3rem;
}
.el-color-picker {
  position: relative;
  top: 0.9rem;
  left: 1rem;
}
.vertcial-strip {
  display: inline-block;
  width: 5px;
  height: 20px;
  background-color: rgb(78, 144, 255);
  border-radius: 2rem;
  margin-right: 1rem;
  position: relative;
  top: 3px;
}
.warp-content /deep/ .el-form-item__label {
  color: #000;
  text-indent: 10px;
}
.input-box-title {
  position: relative;
  padding: 3px 12px;
  display: inline-block;
  font-weight: bold;
}
</style>
