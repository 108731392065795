<template>
  <div class="warp-content">
    <el-form>
      <h2><i class="vertcial-strip"></i>图片内容设置</h2>
      <el-form-item>
        <div class="flex-box">
          <span
            style="color: rgb(78, 144, 255); cursor: pointer; font-size: 16px"
            @click="openDialog"
            >+ 添加图片</span
          >
        </div>
      </el-form-item>
      <h2><i class="vertcial-strip"></i>布局设置</h2>
      <el-form-item>
        <div style="margin-bottom: 20px">位置</div>
        <div class="flex-box flex-position">
          <div class="choice-color">
            <span>横轴：</span>
            <el-input-number
              v-model="wData.props.horizontalAxis"
              controls-position="right"
              :precision="2"
            ></el-input-number>
          </div>
          <div class="choice-color">
            <span> 纵轴：</span>
            <el-input-number
              v-model="wData.props.longitudinalAxis"
              controls-position="right"
              :precision="2"
            ></el-input-number>
          </div>
        </div>
      </el-form-item>

      <el-form-item>
        <div style="margin-bottom: 20px">尺寸</div>
        <div class="flex-box flex-position">
          <div class="choice-color">
            <span> 宽度：</span>
            <el-input-number
              v-model="wData.props.width"
              controls-position="right"
              :precision="2"
            ></el-input-number>
          </div>
          <div class="choice-color">
            <span> 高度：</span>
            <el-input-number
              v-model="wData.props.height"
              controls-position="right"
              :precision="2"
            ></el-input-number>
          </div>
        </div>
      </el-form-item>
    </el-form>
   <drawerDialog :dialog='dialog' @openDialog='openDialog' @closeDialog='closeDialog' :wData='wData'/>
  </div>
</template>

<script>
import popupConfig from "../popupConfig.js";
import drawerDialog from "@/components/drawerDialog";
export default {
  components:{drawerDialog},
  props: {
    wData: {
      type: Object,
      default() {
        return {
          id: "",
          name: "未命名",
          type: "picture-add",
          props: {
            photoUrl:'http://i.topoints.cn/newtest_pc/image/v-img.webp'/*  require("@/assets/v-img.webp") */,
            horizontalAxis: "",
            longitudinalAxis: "",
            height: 0,
            width: 100,
          },
        };
      },
    },
  },
  created(){
    console.log("picture",this.$props.wData)
  },
  data() {
    return {
      fontSizeMap: ["14px", "16px", "18px", "1rem", "2rem", "2.5rem"],
      options: [...popupConfig],
      dialog:false
    };
  },
  methods: {
      openDialog(){
          this.dialog = true
      },
      closeDialog(){
           this.dialog = false
      }
  },
};
</script>

<style scoped>
h2 {
  font-size: 1.2rem;
}
.el-input,
.el-textarea {
  width: 80%;
}
.inine-set {
  padding: 10px;
  box-sizing: border-box;
  margin-right: 1.3rem;
}
.el-color-picker {
  position: relative;
  top: 0.9rem;
  left: 1rem;
}
.vertcial-strip {
  display: inline-block;
  width: 5px;
  height: 20px;
  background-color: rgb(78, 144, 255);
  border-radius: 2rem;
  margin-right: 1rem;
  position: relative;
  top: 3px;
}
.warp-content /deep/ .el-form-item__label {
  color: #000;
  text-indent: 10px;
}
.input-box-title {
  position: relative;
  padding: 3px 12px;
  display: inline-block;
  font-weight: bold;
}
.vertcial-strip {
  display: inline-block;
  width: 5px;
  height: 20px;
  background-color: rgb(78, 144, 255);
  border-radius: 2rem;
  margin-right: 1rem;
  position: relative;
  top: 3px;
}

.input-box-title {
  position: relative;
  padding: 3px 12px;
  display: inline-block;
}

.choice-color {
  flex-direction: column;
  width: 33%;
  /* padding: 1rem 1.2rem; */
  padding: 5px 10px;
  margin-right: 20px;
}

.number-input {
  margin: 0 5px;
}
.flex-position {
  display: flex;
}
</style>
