<template>
  <div class="warp-content">
    <el-form>
      <h2>
        <i class="vertcial-strip"></i>
        背景设置
      </h2>
      <el-form-item>
        <span class="choice-color">
          <span>背景颜色</span>
          <el-color-picker
            v-model="wData.props.backgroundColor"
          ></el-color-picker>
        </span>
      </el-form-item>
      <h2>
        <i class="vertcial-strip"></i>
        文本设置
      </h2>
      <el-form-item>
        <div
          v-for="(item, index) in wData.props.questions"
          :key="index"
          class="question-item"
        >
          <span class="title-item">问题{{ index + 1 }}：</span>
          <el-input
            v-model="item.question"
            placeholder="标题"
            controls-position="right"
            :precision="2"
          ></el-input>
          <el-button type="text" @click="delQusetion(index)">删除</el-button>
          <div class="answer-item">
            <span class="title-item">选项：</span>
            <span v-for="(val, idx) in item.options" :key="idx" class="answer">
              <el-input
                v-model="item.options[idx]"
                size="mini"
                class="input-new-tag"
              >
                <i
                  slot="suffix"
                  class="el-input__icon el-icon-delete"
                  style="cursor: pointer"
                  @click="delAnswer(index, idx)"
                ></i>
              </el-input>
            </span>
            <el-input
              v-if="qusetionShowInputArr[index]"
              ref="newInput"
              v-model="newInputTxt"
              class="input-new-tag"
              autofocus
              size="small"
              @blur="addAnswer(index)"
              @keyup.enter.native="addAnswer(index)"
            ></el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              @click="showInput(index)"
              >+ 选项</el-button
            >
          </div>
        </div>
        <el-button class="add-btn" @click="addQuestion">+ 新增问题</el-button>
      </el-form-item>
      <h2>
        <i class="vertcial-strip"></i>
        问题文本设置
      </h2>
      <el-form-item>
        <div class="inine-set el-form-item">
          字体设置：
          <el-select
            v-model="wData.props.questionFont"
            placeholder="字体大小"
            style="width: 120px"
          >
            <el-option
              v-for="(item, key) in fontSizeMap"
              :key="key"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <div class="flex-box el-form-item">
          <span class="choice-color">
            <span>字体颜色</span>
            <el-color-picker
              v-model="wData.props.questionFontColor"
            ></el-color-picker>
          </span>
          <span v-if="wData.type !== 'flicker-issue'" class="choice-color">
            <span>填充色</span>
            <el-color-picker
              v-model="wData.props.questionFontFillColor"
            ></el-color-picker>
          </span>
        </div>
        <div v-if="wData.type !== 'flicker-issue'" class="el-form-item">
          按钮圆角
          <el-slider
            v-model="wData.props.questionFillet"
            style="width: 300px"
          ></el-slider>
        </div>
      </el-form-item>
      <h2>
        <i class="vertcial-strip"></i>
        选项文本设置
      </h2>
      <el-form-item>
        <div class="inine-set el-form-item">
          字体设置：
          <el-select
            v-model="wData.props.optionFont"
            placeholder="字体大小"
            style="width: 120px"
          >
            <el-option
              v-for="(item, key) in fontSizeMap"
              :key="key"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <div class="flex-box el-form-item">
          <span class="choice-color">
            <span>字体颜色</span>
            <el-color-picker
              v-model="wData.props.optionFontColor"
            ></el-color-picker>
          </span>
          <span class="choice-color">
            <span>填充色</span>
            <el-color-picker
              v-model="wData.props.optionFontFillColor"
            ></el-color-picker>
          </span>
        </div>
        <div class="el-form-item">
          按钮圆角
          <el-slider
            v-model="wData.props.optionFillet"
            style="width: 300px"
          ></el-slider>
        </div>
      </el-form-item>
      <h2>
        <i class="vertcial-strip"></i>
        按钮设置
      </h2>
      <el-form-item label="按钮文字："
        ><el-input v-model="wData.props.buttonContent"></el-input
      ></el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    wData: {
      type: Object,
      default() {
        return {
          id: "",
          name: "未命名",
          type: "issue",
          props: {
            backgroundColor: "",
            questionFont: "",
            questionFontColor: "",
            questionFontFillColor: "",
            questionFillet: 20,
            optionFont: "",
            optionFontColor: "",
            optionFontFillColor: "",
            optionFillet: 20,
            buttonContent: "",
            questions: [
              {
                question: "标题",
                options: ["选项1", "选项2"],
                sort: 1,
              },
            ],
          },
        };
      },
    },
  },
  data() {
    return {
      drawer: false,
      sendType: "img",
      fontSizeMap: ["14px", "16px", "18px", "1rem", "2rem", "2.5rem"],
      newInputTxt: "",
      qusetionShowInputArr: [],
    };
  },
  mounted() {
    this.qusetionShowInputArr = this.wData.props.questions.map(() => false);
  },
  methods: {
    addQuestion() {
      let tempItem = {
        question: "标题",
        options: ["选项1", "选项2"],
        sort: this.wData.props.questions.length + 1,
      };
      this.wData.props.questions.push(tempItem);
      this.qusetionShowInputArr = this.wData.props.questions.map(() => false);
    },
    delQusetion(i) {
      this.wData.props.questions.splice(i, 1);
    },
    delAnswer(k, i) {
      this.wData.props.questions[k].options.splice(i, 1);
    },
    addAnswer(i) {
      if (this.newInputTxt) {
        this.wData.props.questions[i].options.push(this.newInputTxt);
      }
      this.newInputTxt = "";
      this.qusetionShowInputArr[i] = !this.qusetionShowInputArr[i];
      this.$forceUpdate();
    },
    showInput(i) {
      this.qusetionShowInputArr[i] = !this.qusetionShowInputArr[i];
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.newInput[0].focus();
        }, 0);
      });
      this.$forceUpdate();
    },
    handleChange() {
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped>
.flex-box {
  display: flex;
  align-items: center;
}

h2 {
  font-size: 1.2rem;
}
.el-input,
.el-textarea {
  width: 80%;
}
.inine-set {
  display: inline-block;
  padding: 5px 10px;
  box-sizing: border-box;
  margin-right: 1.3rem;
}
.el-color-picker {
  position: relative;
  top: 0.9rem;
  left: 1rem;
}
.vertcial-strip {
  display: inline-block;
  width: 5px;
  height: 20px;
  background-color: rgb(78, 144, 255);
  border-radius: 2rem;
  margin-right: 1rem;
  position: relative;
  top: 3px;
}
.input-box-title {
  position: relative;
  padding: 3px 12px;
  display: inline-block;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.add-btn {
  position: relative;
  left: 50%;
  width: 80%;
  transform: translateX(-50%);
  margin: 10px 0;
}

.el-icon-delete:hover {
  color: #f56c6c;
}
.choice-color {
  padding: 0 15px 10px 10px;
}

.number-input {
  margin: 0 5px;
}
.warp-content /deep/ .el-form-item__label {
  color: #000;
  text-indent: 10px;
  font-size: 15px;
}
</style>
