<template>
  <div style="height: 100%">
    <el-row style="height: 100vh">
      <el-col :span="5" class="content-template">
        <div class="component-title">
          <h1>
            <i class="el-icon-s-ticket"></i>
            组件
          </h1>
        </div>
        <div class="component-list">
          <el-tabs tab-position="left" style="height: 90%">
            <el-tab-pane label="微信">
              <el-card class="widget-card" :body-style="{ padding: '2px 4px' }">
                <span @click="creatWidget('wxCopy')">关注微信号</span>
              </el-card>
              <el-card class="widget-card" :body-style="{ padding: '2px 4px' }">
                <span @click="creatWidget('wx')">微信号名片</span>
              </el-card>
              <div style="font-size: 16px">底部悬浮</div>
              <el-card class="widget-card" :body-style="{ padding: '2px 4px' }">
                <span @click="creatWidget('bottom-wx')">微信号名片</span>
              </el-card>
              <el-card class="widget-card" :body-style="{ padding: '2px 4px' }">
                <span @click="creatWidget('bottom-long-wx')">长按复制</span>
              </el-card>
            </el-tab-pane>
            <el-tab-pane label="图片">
              <el-card class="widget-card" :body-style="{ padding: '2px 4px' }">
                <div class="prc" @click="creatWidget('picture-add')">
                  <span>+</span>
                  <span>点击添加图片</span>
                </div>
              </el-card>
              <el-card class="widget-card" :body-style="{ padding: '2px 4px' }">
                <span @click="creatWidget('picture-carousel')">
                  <img
                    src="https://p1-tt.byteimg.com/img/ad-tetris-site/brick_tpl_picture_group_normal.png~noop.webp"
                    alt=""
                    style="width: 100%"
                  />
                </span>
              </el-card>
            </el-tab-pane>
            <el-tab-pane label="文本">
              <el-card class="widget-card" :body-style="{ padding: '2px 4px' }">
                <span @click="creatWidget('text')">请添加文本内容</span>
              </el-card>
              <el-card class="widget-card" :body-style="{ padding: '2px 4px' }">
                <span @click="creatWidget('text-wx')">请添加微信文本</span>
              </el-card>
            </el-tab-pane>
            <el-tab-pane label="按钮">
              <el-card class="widget-card" :body-style="{ padding: '2px 4px' }">
                <span @click="creatWidget('long-copy')">长按复制</span>
              </el-card>
              <el-card class="widget-card" :body-style="{ padding: '2px 4px' }">
                <span @click="creatWidget('click-copy')">点击复制</span>
              </el-card>
            </el-tab-pane>

            <el-tab-pane label="引导图">
              <el-card class="widget-card" :body-style="{ padding: '2px 4px' }">
                <div class="prc" @click="creatWidget('guide-wx')">
                  <span>+</span>
                  <span>添加微信引导图</span>
                </div>
              </el-card>
              <el-card class="widget-card" :body-style="{ padding: '2px 4px' }">
                <div class="prc" @click="creatWidget('guide-official')">
                  <span>+</span>
                  <span>添加公众号引导图</span>
                </div>
              </el-card>
            </el-tab-pane>
            <el-tab-pane label="互动页">
              <el-card class="widget-card" :body-style="{ padding: '2px 4px' }">
                <span @click="creatWidget('issue')">添加问题列表</span>
              </el-card>
              <el-card class="widget-card" :body-style="{ padding: '2px 4px' }">
                <span @click="creatWidget('action-issue')"
                  >按钮常驻问题列表</span
                >
              </el-card>
              <el-card class="widget-card" :body-style="{ padding: '2px 4px' }">
                <span @click="creatWidget('flicker-issue')">流程问题</span>
              </el-card>
            </el-tab-pane>
          </el-tabs>
          <div style="padding: 13px">
            <img id="img" :src="cover" style="width: 200px" />
          </div>
        </div>
      </el-col>
      <el-col :span="10" class="content-preview">
        <div class="flex-box" style="justify-content: space-between">
          <div style="width: 40%">
            <el-button @click="pagePreview">预览</el-button>
            <el-button v-show="opt.currentPage !== 0" @click="backPage"
              >上一页</el-button
            >
          </div>
          <div class="flex-box">
            <el-button type="primary" @click="saveTemplageInfo"
              >保存模板</el-button
            >
          </div>
        </div>
        <div class="preview-box">
          <sitePreview
            ref="refSite"
            :site-data="siteData"
            :opt="opt"
            class="preview-scroll"
          />
        </div>
      </el-col>
      <el-col :span="9" class="content-setting" style="overflow: auto">
        <copyButtonSetting
          v-if="
            siteData.pages[opt.currentPage].widgets[opt.currentWidget].type ==
              'long-copy' ||
            siteData.pages[opt.currentPage].widgets[opt.currentWidget].type ==
              'click-copy'
          "
          :w-data="siteData.pages[opt.currentPage].widgets[opt.currentWidget]"
        />
        <wxCopyCardSetting
          v-else-if="
            siteData.pages[opt.currentPage].widgets[opt.currentWidget].type ==
            'wxCopy'
          "
          :w-data="siteData.pages[opt.currentPage].widgets[opt.currentWidget]"
        />
        <wxVCardSetting
          v-else-if="
            siteData.pages[opt.currentPage].widgets[opt.currentWidget].type ==
              'wx' ||
            siteData.pages[opt.currentPage].widgets[opt.currentWidget].type ==
              'bottom-long-wx' ||
            siteData.pages[opt.currentPage].widgets[opt.currentWidget].type ==
              'bottom-wx'
          "
          :w-data="siteData.pages[opt.currentPage].widgets[opt.currentWidget]"
        />
        <pictureSetting
          v-else-if="
            siteData.pages[opt.currentPage].widgets[opt.currentWidget].type ==
            'picture-add'
          "
          :w-data="siteData.pages[opt.currentPage].widgets[opt.currentWidget]"
        />
        <carouselSetting
          v-else-if="
            siteData.pages[opt.currentPage].widgets[opt.currentWidget].type ==
            'picture-carousel'
          "
          :w-data="siteData.pages[opt.currentPage].widgets[opt.currentWidget]"
        />
        <wxGuide
          v-else-if="
            siteData.pages[opt.currentPage].widgets[opt.currentWidget].type ==
            'guide-wx'
          "
          :w-data="siteData.pages[opt.currentPage].widgets[opt.currentWidget]"
        ></wxGuide>
        <officalGuide
          v-else-if="
            siteData.pages[opt.currentPage].widgets[opt.currentWidget].type ==
            'guide-official'
          "
          :w-data="siteData.pages[opt.currentPage].widgets[opt.currentWidget]"
        ></officalGuide>
        <wxTextSetting
          v-else-if="
            siteData.pages[opt.currentPage].widgets[opt.currentWidget].type ==
              'text' ||
            siteData.pages[opt.currentPage].widgets[opt.currentWidget].type ==
              'text-wx'
          "
          :w-data="siteData.pages[opt.currentPage].widgets[opt.currentWidget]"
        />
        <interactionSetting
          v-else-if="
            siteData.pages[opt.currentPage].widgets[opt.currentWidget].type ==
              'issue' ||
            siteData.pages[opt.currentPage].widgets[opt.currentWidget].type ==
              'action-issue' ||
            siteData.pages[opt.currentPage].widgets[opt.currentWidget].type ==
              'flicker-issue'
          "
          :w-data="siteData.pages[opt.currentPage].widgets[opt.currentWidget]"
        />
        <div v-else>未定义组件</div>
        <div class="flex-box" style="margin-bottom: 10px; margin-top: 30px">
          <el-button
            round
            size="medium"
            type="primary"
            icon="el-icon-delete"
            @click="removeWidget"
            >删除组件</el-button
          >
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import copyButtonSetting from "@/components/editor/copyButtonSetting.vue";
import wxCopyCardSetting from "@/components/editor/wxCopyCardSetting.vue";
import wxVCardSetting from "@/components/editor/wxVCardSetting.vue";
import wxTextSetting from "@/components/editor/wxTextSetting.vue";
import interactionSetting from "@/components/editor/interactionSetting.vue";
import sitePreview from "@/components/sitePreview.vue";
import pictureSetting from "@/components/editor/picture.vue";
import carouselSetting from "@/components/editor/carousel.vue";
import officalGuide from "@/components/editor/officalGuide.vue";
import wxGuide from "@/components/editor/wxGuide.vue";
export default {
  components: {
    copyButtonSetting,
    wxCopyCardSetting,
    sitePreview,
    wxVCardSetting,
    pictureSetting,
    carouselSetting,
    officalGuide,
    wxGuide,
    wxTextSetting,
    interactionSetting,
  },
  data() {
    return {
      siteData: {
        templateId: "",
        mainPage: 0,
        pages: [
          {
            id: "page1",
            name: "页面1",
            widgets: [
              {
                id: "id1",
                type: "wx",
                props: {
                  assemblyBackgroundColor: "#ffffff",
                  assemblyFontColor: "",
                  assemblyHeight: "",
                  assemblyFont: "14px",
                  followButtonBackgroundColor: "#13ce66",
                  followButtonFontColor: "#ffffff",
                  followButtonFont: "14px",
                  followButtonContent: "关注",
                  followButtonFillet: 20,
                  followButtonHeight: "25",
                  followButtonWidth: "50",
                  wechatNumber: "weixin帐号",
                  wechatNumberFont: "",
                  wechatNumberColor: "",
                  wechatPictureUrl:
                    "http://240ps.com/jc/Dfile/20160907/j1609072_2.jpg",
                  wechatPictureHeight: 20,
                  wechatPictureWidth: 20,
                  popupType: "",
                  finger: "",
                },
              },
            ],
          },
          {
            id: "page2",
            name: "页面2",
            widgets: [
              {
                id: "id3",
                type: "issue",
                props: {
                  backgroundColor: "",
                  questionFont: "14px",
                  questionFontColor: "",
                  questionFontFillColor: "",
                  questionFillet: 20,
                  optionFont: "14px",
                  optionFontColor: "",
                  optionFontFillColor: "",
                  optionFillet: 20,
                  buttonContent: "按钮",
                  questions: [
                    {
                      question: "标题",
                      options: ["选项1", "选项2"],
                      sort: 1,
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      opt: {
        isEditPreview: true,
        currentPage: 0,
        currentWidget: 0,
        channelCode: "",
        landinCode: "",
        isOfficial: true,
      },
      cover: "",
      templateTypeMap: {
        wxCopy: 1,
        wx: 2,
        "bottom-wx": 2,
        "bottom-long-wx": 2,
        "photo-rotation": 4,
        wxTxt: 5,
        "long-copy": 6,
        "click-copy": 6,
        issue: 7,
        "action-issue": 7,
        "flicker-issue": 7,
        wxGuide: 8,
        "picture-add": 3,
        "picture-carousel": 4,
        "guide-wx": 8,
      },
    };
  },
  watch: {
    siteData: {
      handler: (nVal, oVal) => {
        if (nVal) {
          // this.canvas();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.siteData.templateId = this.$route.query.id;
    this.siteData.templateId && this.getTemplateInfo();
    // this.canvas();
  },
  methods: {
    canvas() {
      // setInterval(() => {
      let dom = document.querySelector(".preview-box");
      html2canvas(dom, {
        allowTaint: true,
        useCORS: true,
      }).then((canvas) => {
        document.body.appendChild(canvas);
        this.cover = canvas.toDataURL();
      });
      // }, 5000);
    },
    creatWidget(type) {
      let widget = null;
      switch (type) {
        case "text":
          widget = {
            type: type,
            props: {
              textPre: "默认文本文字",
              wechatNumber: "",
              textSuf: "",
              font: "16px",
              color: "",
              horizontalAxis: "",
              longitudinalAxis: "",
              width: 320,
            },
          };
          break;
        case "text-wx":
          widget = {
            type: type,
            props: {
              textPre: "添加微信号",
              wechatNumber: "wxnumber",
              textSuf: "了解更多",
              font: "16px",
              color: "",
              horizontalAxis: "",
              longitudinalAxis: "",
              width: 320,
            },
          };
          break;
        case "long-copy":
          widget = {
            type: type,
            props: {
              buttonBackgroundColor: "#2479E9",
              buttonFontColor: "#ffffff",
              buttonFont: "14px",
              buttonFillet: 20,
              buttonHeight: 25,
              buttonWidth: 50,
              wechatNumber: "默认微信",
              buttonContent: "关注",
              popupType: 1,
            },
          };
          break;
        case "click-copy":
          widget = {
            type: type,
            props: {
              buttonBackgroundColor: "#2479E9",
              buttonFontColor: "#ffffff",
              buttonFont: "14px",
              buttonFillet: 20,
              buttonHeight: 25,
              buttonWidth: 50,
              wechatNumber: "默认微信",
              buttonContent: "关注",
              popupType: 1,
            },
          };
          break;
        case "wxCopy":
          widget = {
            type: type,
            props: {
              wechatNumber: "weixin帐号",
              content: "长按复制微信号，去微信搜索添加",
              basicFont: "",
              basicFontColor: "#ffffff",
              basicFontBackgroundColor: "#2479E9",
              wechatNumberFont: "",
              wechatNumberColor: "#de691e",
              copywritingFont: "",
              horizontalAxis: "",
              longitudinalAxis: "",
              height: "",
            },
          };
          break;
        case "wx":
          widget = {
            type: type,
            props: {
              assemblyBackgroundColor: "#ffffff",
              assemblyFontColor: "",
              assemblyHeight: "",
              assemblyFont: "14px",
              assemblyContent: "关注微信号",
              followButtonBackgroundColor: "#13ce66",
              followButtonFontColor: "#ffffff",
              followButtonFont: "14px",
              followButtonContent: "关注",
              followButtonFillet: 20,
              followButtonHeight: "15",
              followButtonWidth: "50",
              wechatNumber: "weixin帐号",
              wechatNumberFont: "",
              wechatNumberColor: "",
              wechatPictureUrl:
                "http://240ps.com/jc/Dfile/20160907/j1609072_2.jpg",
              wechatPictureHeight: "20",
              wechatPictureWidth: "20",
              popupType: 2,
              finger: 0,
            },
          };
          break;
        case "bottom-wx":
          widget = {
            type: type,
            props: {
              assemblyBackgroundColor: "#ffffff",
              assemblyFontColor: "",
              assemblyHeight: "",
              assemblyFont: "14px",
              assemblyContent: "关注微信号",
              followButtonBackgroundColor: "#13ce66",
              followButtonFontColor: "#ffffff",
              followButtonFont: "14px",
              followButtonContent: "关注",
              followButtonFillet: 20,
              followButtonHeight: "25",
              followButtonWidth: "50",
              wechatNumber: "weixin帐号",
              wechatNumberFont: "",
              wechatNumberColor: "",
              wechatPictureUrl:
                "http://240ps.com/jc/Dfile/20160907/j1609072_2.jpg",
              wechatPictureHeight: "20",
              wechatPictureWidth: "20",
              popupType: 2,
              finger: 0,
            },
          };
          break;
        case "bottom-long-wx":
          widget = {
            type: type,
            props: {
              assemblyBackgroundColor: "#ffffff",
              assemblyFontColor: "",
              assemblyHeight: "",
              assemblyFont: "14px",
              assemblyContent: "关注微信号",
              followButtonBackgroundColor: "#13ce66",
              followButtonFontColor: "#ffffff",
              followButtonFont: "14px",
              followButtonContent: "关注",
              followButtonFillet: 20,
              followButtonHeight: "15",
              followButtonWidth: "50",
              wechatNumber: "weixin帐号",
              wechatNumberFont: "",
              wechatNumberColor: "",
              wechatPictureUrl:
                "http://240ps.com/jc/Dfile/20160907/j1609072_2.jpg",
              wechatPictureHeight: "20",
              wechatPictureWidth: "20",
              popupType: 1,
              finger: 0,
            },
          };
          break;
        case "picture-add":
          widget = {
            type: type,
            props: {
              photoUrl:
                "http://i.topoints.cn/newtest_pc/image/v-img.webp" /* require("@/assets/v-img.webp") */,
              horizontalAxis: "",
              longitudinalAxis: "",
              height: 200,
              width: 100,
            },
          };
          break;
        case "picture-carousel":
          widget = {
            type: type,
            props: {
              rotationType: 1, //图片轮播类型（1.轮播图 2.相册）
              height: 100, //组件高度
              indicationPoint: 0, // 是否展示指示点 0.展示 1.不展示
              loops: 0, //是否环路 0.环路 1.不环路
              automaticRotation: 0, //是否自动轮播 0.自动轮播 1.不自动轮播
              rotationTime: 6000, //轮播时间（ms）默认6000ms
              photoList: [
                {
                  rotationId: -1,
                  url: "http://i.topoints.cn/newtest_pc/image/v-img.webp" /*  require("@/assets/v-img.webp") */,
                  label: "",
                  link: "",
                  sort: "",
                },
              
              ],
            },
          };
          break;
        case "guide-wx":
          widget = {
            type: type,
            props: {
              wechatNumber: "s", //微信号
              popupType: 1, //  弹窗类型（0.点击复制 1.长按复制）
              photoUrl:
                "http://i.topoints.cn/newtest_pc/image/v-img.webp" /*  require("@/assets/v-img.webp") */, //图片
            },
          };
          break;
        case "guide-official":
          widget = {
            type: type,
            props: {
              guideType: 1, // 引导类别(0.微信引导 1.公众号引导)
              wechatNumber: "s", //微信号
              popupType: 1, //  弹窗类型（0.点击复制 1.长按复制）
              photoUrl: require("@/assets/url1.webp"), //图片
              photoUrl2: require("@/assets/url2.webp"), // 图片2
              photoUrl3: require("@/assets/url3.webp"), //图片3
              backgroundColor: "#eee", //背景颜色
              font: 24, // 字体大小
              fontColor: "#eeeeee",
            },
          };
          break; // 字体颜色
        case "issue":
          widget = {
            type: type,
            props: {
              backgroundColor: "",
              questionFont: "14px",
              questionFontColor: "",
              questionFontFillColor: "",
              questionFillet: 20,
              optionFont: "14px",
              optionFontColor: "",
              optionFontFillColor: "",
              optionFillet: 20,
              buttonContent: "按钮",
              questions: [
                {
                  question: "标题",
                  options: ["选项1", "选项2"],
                  sort: 1,
                },
              ],
            },
          };
          break;
        case "action-issue":
          widget = {
            type: type,
            props: {
              backgroundColor: "",
              questionFont: "14px",
              questionFontColor: "",
              questionFontFillColor: "",
              questionFillet: 20,
              optionFont: "14px",
              optionFontColor: "",
              optionFontFillColor: "",
              optionFillet: 20,
              buttonContent: "按钮",
              questions: [
                {
                  question: "标题",
                  options: ["选项1", "选项2"],
                  sort: 1,
                },
              ],
            },
          };
          break;
        case "flicker-issue":
          widget = {
            type: type,
            props: {
              backgroundColor: "",
              questionFont: "14px",
              questionFontColor: "",
              questionFontFillColor: "",
              questionFillet: 20,
              optionFont: "14px",
              optionFontColor: "",
              optionFontFillColor: "",
              optionFillet: 20,
              buttonContent: "按钮",
              questions: [
                {
                  question: "标题",
                  options: ["选项1", "选项2"],
                  sort: 1,
                },
              ],
            },
          };
          break;
      }
      this.$nextTick(() => {
        let findIndex = this.siteData.pages[
          this.opt.currentPage
        ].widgets.findIndex(
          (i) => i.type == "bottom-wx" || i.type == "bottom-long-wx"
        );
        if (
          (findIndex != -1 && widget.type == "bottom-wx") ||
          (findIndex != -1 && widget.type == "bottom-long-wx")
        ) {
          this.siteData.pages[this.opt.currentPage].widgets.splice(
            findIndex,
            1,
            widget
          );
        } else {
          this.siteData.pages[this.opt.currentPage].widgets.push(widget);
        }
      });
    },
    removeWidget() {
      let data = this.siteData.pages[this.opt.currentPage].widgets;
      data.splice(this.opt.currentWidget, 1);
      if (this.opt.currentWidget > data.length - 1 && data.length > 0) {
        this.opt.currentWidget = data.length - 1;
      }
    },
    backPage() {
      this.$set(this.opt, "currentPage", 0);
      this.opt.currentWidget = 0;
      this.$forceUpdate();
    },
    saveTemplageInfo() {
      let tempParams = JSON.parse(JSON.stringify(this.siteData));
      tempParams = tempParams.pages.reduce((prev, val, i) => {
        val.widgets.map((v, k) => {
          if (v.props) {
            if (this.templateTypeMap[v.type] == 7) {
              v.props.questions = v.props.questions.map((v) => {
                v.options = v.options.join(",");
                return v;
              });
            }
            prev.push({
              data: v.props,
              sort: k + 1,
              type: this.templateTypeMap[v.type],
              level: i + 1,
              typeName: v.type,
            });
          }
        });
        return prev;
      }, []);

      if (tempParams.length > 0) {
        this.$http
          .post("/function/component/edit", {
            data: tempParams,
            templateId: this.siteData.templateId,
          })
          .then((response) => {
            if (response.data.code === 200) {
              this.$message({
                message: "保存成功",
                type: "success",
                duration: 2000,
              });
            }
          })
          .finally(() => {
            // this.loading = false;
          });
      }
    },
    getTemplateInfo() {
      this.$http
        .get(
          "/function/component/getTemplateById?templateId=" +
            this.siteData.templateId
        )
        .then((response) => {
          if (response.data.code === 200) {
            let tempInfo = JSON.parse(JSON.stringify(response.data.data));
            this.siteData.pages = this.siteData.pages.map((v, i) => {
              v.widgets = tempInfo.reduce((prev, val) => {
                if (val.level == i + 1) {
                  if (val.type == 7) {
                    val.data.questions = val.data.questions.map((value) => {
                      value.options = value.options.split(",");
                      return value;
                    });
                  }
                  prev.push({
                    type: val.typeName,
                    props: val.data,
                  });
                }
                return prev;
              }, []);
              return v;
            });
            console.log(this.siteData.pages, "tempInfo ");
          }
        });
    },
    pagePreview() {
      // let hm = HtmlTemplate(this.siteData);
      // let blob = new Blob([hm], {
      //   type: "text/html",
      // });
      // this.url = URL.createObjectURL(blob);
      // let query = this.$route.query;
      // window.open(this.url);
      // if (query.key) {
      //   window.open(
      //     this.url +
      //       `#?key=${query.key}&isOfficial=${query.isOfficial}&ch=${query.ch}`
      //   );
      // } else if (query.la) {
      //   window.open(
      //     this.url +
      //       `#?la=${query.la}&isOfficial=${query.isOfficial}&ch=${query.ch}`
      //   );
      // }
      let routeInfo = this.$router.resolve({
        path: `/template?id=` + this.siteData.templateId,
      });
      window.open(routeInfo.href, "_blank");
    },
  },
};
</script>

<style scoped>
.widget-card {
  width: 200px;
  margin: 20px 20px 10px 20px;
  padding: 2px;
  border: 1px dashed #e6e6e6;
  color: gray;
  text-align: center;
  cursor: pointer;
}

.widget-card:hover {
  box-shadow: 1px 1px 10px #e6e6e6 !important;
}
.content-template {
  height: 100%;
  border-right: 1px solid #e6e6e6;
  box-shadow: 2px 4px 5px #e6e6e6;
}

.content-preview {
  padding: 1rem;
  box-sizing: border-box;
  height: 100%;
  background-color: #f4f7f9;
}
.preview-box {
  position: relative;
  margin: 30px auto;
  width: 320px;
  height: 600px;
  background-color: #fff;
  /* 让fixed相对它定位 */
  /* transform: scale(0.94); */
  overflow: hidden;
}

.preview-scroll {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.preview-scroll::-webkit-scrollbar {
  display: none;
}
.content-setting {
  height: 100%;
  border-left: 1px solid #e6e6e6;
  box-shadow: -2px 4px 5px #e6e6e6;
  padding: 1rem 1rem 2rem;
  box-sizing: border-box;
}

.component-title {
  width: 100%;
  height: 5rem;
  box-sizing: border-box;
  text-align: center;
}

.component-title h1 {
  width: 100%;
  height: 100%;
  line-height: 5rem;
  background-color: #fff;
  /* color: gray; */
  box-shadow: 2px 2px 4px #e6e6e6;
  font-size: 1.5rem;
  margin: 0;
  letter-spacing: 10px;
}
.flex-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.prc {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.prc > span {
  font-size: 20px;
}
</style>
