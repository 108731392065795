const config = [
  {
    value: 1,
    label: "长按复制",
  },
  {
    value: 2,
    label: "点击复制",
  },
  // {
  //   value: 3,
  //   label: "二维码",
  // },
  // {
  //   value: 4,
  //   label: "跳转页面",
  // },
];

export default config;
