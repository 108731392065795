<template>
  <div class="warp-content">
    <el-form>
      <h2><i class="vertcial-strip"></i>图片内容设置</h2>
      <el-form-item>
        <div class="flex-box">
          <span
            style="
              color: rgb(78, 144, 255);
              cursor: pointer;
              font-size: 14px;
              margin-right: 10px;
            "
            @click="openDialog(1)"
            >图片1</span
          >
          <span
            style="
              color: rgb(78, 144, 255);
              cursor: pointer;
              font-size: 14px;
              margin-right: 10px;
            "
            @click="openDialog(2)"
            >图片2</span
          >
          <span
            style="
              color: rgb(78, 144, 255);
              cursor: pointer;
              font-size: 14px;
              margin-right: 10px;
            "
            @click="openDialog()"
            >图片3</span
          >
        </div>
        <div>
          <span>微信号码：</span>
          <el-input v-model="wData.props.wechatNumber"></el-input>
        </div>
      </el-form-item>
      <h2><i class="vertcial-strip"></i>弹窗类型设置</h2>
      <el-form-item>
        <el-switch
          v-model="wData.props.popupType"
          active-text="长按复制"
          inactive-text="点我复制"
          :active-value="1"
          :inactive-value="0"
        >
        </el-switch>
        <div class="inine-set">
          组件背景颜色：
          <el-color-picker
            v-model="wData.props.backgroundColor"
          ></el-color-picker>
        </div>
      </el-form-item>
      <h2><i class="vertcial-strip"></i>文字设置</h2>
      <el-form-item>
        <div class="flex-box flex-position">
          <div class="choice-color setposition">
            <span> 文字大小：</span>
            <el-select
              v-model="wData.props.font"
              placeholder="字体大小"
              style="width: 150px"
            >
              <el-option
                v-for="(item, key) in fontSizeMap"
                :key="key"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <div class="inine-set setposition">
            <span> 字体颜色：</span>
            <el-color-picker v-model="wData.props.fontColor"></el-color-picker>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <drawerDialog
      :dialog="dialog"
      @openDialog="openDialog"
      @closeDialog="closeDialog"
      :wData='wData'
      :openid="openid"
    />
  </div>
</template>

<script>
import popupConfig from "../popupConfig.js";
import drawerDialog from "@/components/drawerDialog";
export default {
  components: { drawerDialog },
  props: {
    wData: {
      type: Object,
      default() {
        return {
          id: "",
          name: "未命名",
          type: "picture-add",
          props: {
            guideType: 1, // 引导类别(0.微信引导 1.公众号引导)
            wechatNumber: "s", //微信号
            popupType: 0, //  弹窗类型（0.点击复制 1.长按复制）
            photoUrl: "", //图片
            photoUrl2: "", // 图片2
            photoUrl3: "", //图片3
            backgroundColor: "#eee", //背景颜色
            font: 24, // 字体大小
            fontColor: "#eeeeee", // 字体颜色
          },
        };
      },
    },
  },
  data() {
    return {
      fontSizeMap: ["14px", "16px", "18px", "1rem", "2rem", "2.5rem"],
      options: [...popupConfig],
      dialog: false,
      openid: 1,
    };
  },
  mounted() {
    console.log("offical", this.wData);
  },
  methods: {
    openDialog(id) {
      this.dialog = true;
      this.openid = id;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 1.2rem;
}
.el-input,
.el-textarea {
  width: 80%;
}
.inine-set {
  padding: 10px;
  box-sizing: border-box;
  margin-right: 1.3rem;
}
.el-color-picker {
  position: relative;
  top: 0rem;
  left: 1rem;
}
.vertcial-strip {
  display: inline-block;
  width: 5px;
  height: 20px;
  background-color: rgb(78, 144, 255);
  border-radius: 2rem;
  margin-right: 1rem;
  position: relative;
  top: 3px;
}
.warp-content /deep/ .el-form-item__label {
  color: #000;
  text-indent: 10px;
}
.input-box-title {
  position: relative;
  padding: 3px 12px;
  display: inline-block;
  font-weight: bold;
}
.vertcial-strip {
  display: inline-block;
  width: 5px;
  height: 20px;
  background-color: rgb(78, 144, 255);
  border-radius: 2rem;
  margin-right: 1rem;
  position: relative;
  top: 3px;
}

.input-box-title {
  position: relative;
  padding: 3px 12px;
  display: inline-block;
}

.choice-color {
  /*  flex-direction: column; */
  /*  width: 33%; */
  /* padding: 1rem 1.2rem; */
  padding: 5px 10px;
  margin-right: 20px;
}

.number-input {
  margin: 0 5px;
}
.flex-position {
  display: flex;
  align-items: center;
}
.setposition {
  display: flex;
  align-items: center;
}
</style>
