<template>
  <div class="warp-content">
    <el-form>
      <h2><i class="vertcial-strip"></i>轮播类型</h2>
      <el-form-item>     
        <el-select v-model="wData.props.rotationType" style="width: 100%">
          <el-option label="轮播图" :value="1"></el-option>
          <el-option label="相册" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <h2><i class="vertcial-strip"></i>图片内容</h2>
      <el-form-item>
        <div>组件高度</div>
        <el-input v-model="wData.props.height" style="width: 100%"></el-input>
        <div
          class="listitem"
          v-for="(item,index) in wData.props.photoList"
          :key="index"
        >
          <img
            :src="item.url"
            alt=""
            style="width: 76px; height: 45px; margin-right: 10px"
          />
          <div class="listitem_right">
            <div>标签：</div>
            <el-input v-model="item.label" style="width: 100%"></el-input>
            <div>链接：</div>
            <el-input v-model="item.link" style="width: 100%"></el-input>
          </div>
          <span @click="handleDelete(item)" style="cursor: pointer">删除</span>
        </div>
        <div class="carousel_btn">
          <el-button
            type="primary"
            style="border-radius: 20px; height: 36px"
            @click="openDialog"
            >+ 添加图片
          </el-button>
        </div>
      </el-form-item>

      <el-form-item>
        <div>
          <el-switch
            v-model="wData.props.indicationPoint"
            active-text="是否隐藏指示点"
            :active-value="0"
            :inactive-value="1"
          >
          </el-switch>
        </div>
        <div>
          <el-switch
            v-model="wData.props.loops"
            active-text="是否关闭环路"
            :active-value="0"
            :inactive-value="1"
          >
          </el-switch>
        </div>
        <div>
          <el-switch
            v-model="wData.props.automaticRotation"
            active-text="是否关闭自动轮播"
            :active-value="0"
            :inactive-value="1"
          >
          </el-switch>
        </div>
      </el-form-item>

      <el-form-item>
        <div>单位：ms</div>
        <el-input v-model="wData.props.rotationTime"></el-input>
      </el-form-item>
    </el-form>
    <drawerDialog
      :dialog="dialog"
      @openDialog="openDialog"
      @closeDialog="closeDialog"
      :wData="wData"
     
    />
  </div>
</template>

<script>
import popupConfig from "../popupConfig.js";
import drawerDialog from "@/components/drawerDialog";
/* require('../../assets/v-img.webp') */
export default {
  components: { drawerDialog },
  props: {
    wData: {
      type: Object,
      default() {
        return {
          id: "",
          name: "未命名",
          type: "picture-carousel",
          props: {
            rotationType: 1, //图片轮播类型（1.轮播图 2.相册）
            height: 100, //组件高度
            indicationPoint: 0, // 是否展示指示点 0.展示 1.不展示
            loops: 0, //是否环路 0.环路 1.不环路
            automaticRotation: 100, //是否自动轮播 0.自动轮播 1.不自动轮播
            rotationTime: 6000, //轮播时间（ms）默认6000ms
            photoList: [
              {
                rotationId: -1,
                url: require("@/assets/url1.webp"),
                label: "",
                link: "",
                sort: "",
                 photoList:[{rotationId:1,url:require("@/assets/url1.webp"),label:'',link:'',sort:''}],
              },
            ],
          },
        };
      },
    },
  },
 
  data() {
    return {
      fontSizeMap: ["14px", "16px", "18px", "1rem", "2rem", "2.5rem"],
      options: [...popupConfig],
      dialog: false,
      selectList: [{ id: 1, photoUrl:'http://i.topoints.cn/newtest_pc/image/v-img.webp' /* require("@/assets/v-img.webp") */ }],
    };
  },
  methods: {
    openDialog() {
    
      this.dialog = true;

    },
    closeDialog() {
      this.dialog = false;
    },
    hanleSelect(selects) {
      this.selectList = selects;
    },
    //删除
    handleDelete(item) {
      /*  this.$confirm("是否确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http
          .delete("/function/photo/delete?id=" + id)
          .then((response) => {
            // .then((response) => {
            if (response.data.code === 200) {
              this.$message.success("删除成功");
              this.getPictureList();
             
            }
          });
      }); */
    console.log("删除",this.$props.wData.props.photoList,item)
   let arrIndex =  this.$props.wData.props.photoList.findIndex(i =>i.id == item.id)
  
   this.$props.wData.props.photoList.splice(arrIndex,1)
    // this.$props.wData.props.photoList = this.$props.wData.props.photoList.filter((i =>i.rotationId != item.rotationId))
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 1.2rem;
}
.el-input,
.el-textarea {
  width: 80%;
}
.inine-set {
  padding: 10px;
  box-sizing: border-box;
  margin-right: 1.3rem;
}
.el-color-picker {
  position: relative;
  top: 0.9rem;
  left: 1rem;
}
.vertcial-strip {
  display: inline-block;
  width: 5px;
  height: 20px;
  background-color: rgb(78, 144, 255);
  border-radius: 2rem;
  margin-right: 1rem;
  position: relative;
  top: 3px;
}
.warp-content /deep/ .el-form-item__label {
  color: #000;
  text-indent: 10px;
}
.input-box-title {
  position: relative;
  padding: 3px 12px;
  display: inline-block;
  font-weight: bold;
}
.vertcial-strip {
  display: inline-block;
  width: 5px;
  height: 20px;
  background-color: rgb(78, 144, 255);
  border-radius: 2rem;
  margin-right: 1rem;
  position: relative;
  top: 3px;
}

.input-box-title {
  position: relative;
  padding: 3px 12px;
  display: inline-block;
}

.choice-color {
  flex-direction: column;
  width: 33%;
  /* padding: 1rem 1.2rem; */
  padding: 5px 10px;
  margin-right: 20px;
}

.number-input {
  margin: 0 5px;
}
.flex-position {
  display: flex;
}
.listitem {
  display: flex;
  background: #f5f5f5;
  align-items: center;
  padding: 0 20px 20px;
  margin-top: 20px;
}
.listitem_right {
  flex: 1;
  margin-right: 10px;
  cursor: pointer;
}

.carousel_btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
