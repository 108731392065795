<template>
  <div class="warp-content">
    <el-form>
      <h2><i class="vertcial-strip"></i>图片内容设置</h2>
      <el-form-item>
        <div class="flex-box">
          <span
            style="
              color: rgb(78, 144, 255);
              cursor: pointer;
              font-size: 14px;
              margin-right: 10px;
            "
            @click="openDialog"
            >添加图片</span
          >
         
        </div>
        <div>
          <span>微信号码：</span>
          <el-input v-model="wData.props.wechatNumber"></el-input>
        </div>
      </el-form-item>
      <h2><i class="vertcial-strip"></i>弹窗类型设置</h2>
      <el-form-item>
        <el-switch
          v-model="wData.props.popupType"
          active-text="长按复制"
          inactive-text="点我复制"
          :active-value="1"
          :inactive-value="0"
        >
        </el-switch>
       
      </el-form-item>
    
    </el-form>
    <drawerDialog
      :dialog="dialog"
      @openDialog="openDialog"
      @closeDialog="closeDialog"
      :wData='wData'
    />
  </div>
</template>

<script>
import popupConfig from "../popupConfig.js";
import drawerDialog from "@/components/drawerDialog";
export default {
  components: { drawerDialog },
  props: {
    wData: {
      type: Object,
      default() {
        return {
          id: "",
          name: "未命名",
          type: "picture-add",
          props: {
             wechatNumber: "s", //微信号
              popupType: 0, //  弹窗类型（0.点击复制 1.长按复制）
              photoUrl: require("@/assets/url1.webp"), //图片
          },
        };
      },
    },
  },
  data() {
    return {
      fontSizeMap: ["14px", "16px", "18px", "1rem", "2rem", "2.5rem"],
      options: [...popupConfig],
      dialog: false,
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
 
};
</script>

<style scoped>
h2 {
  font-size: 1.2rem;
}
.el-input,
.el-textarea {
  width: 80%;
}
.inine-set {
  padding: 10px;
  box-sizing: border-box;
  margin-right: 1.3rem;
}
.el-color-picker {
  position: relative;
  top: 0rem;
  left: 1rem;
}
.vertcial-strip {
  display: inline-block;
  width: 5px;
  height: 20px;
  background-color: rgb(78, 144, 255);
  border-radius: 2rem;
  margin-right: 1rem;
  position: relative;
  top: 3px;
}
.warp-content /deep/ .el-form-item__label {
  color: #000;
  text-indent: 10px;
}
.input-box-title {
  position: relative;
  padding: 3px 12px;
  display: inline-block;
  font-weight: bold;
}
.vertcial-strip {
  display: inline-block;
  width: 5px;
  height: 20px;
  background-color: rgb(78, 144, 255);
  border-radius: 2rem;
  margin-right: 1rem;
  position: relative;
  top: 3px;
}

.input-box-title {
  position: relative;
  padding: 3px 12px;
  display: inline-block;
}

.choice-color {
 /*  flex-direction: column; */
 /*  width: 33%; */
  /* padding: 1rem 1.2rem; */
  padding: 5px 10px;
  margin-right: 20px;
}

.number-input {
  margin: 0 5px;
}
.flex-position {
  display: flex;
  align-items: center;
  
}
.setposition{
  display:flex;
  align-items: center;
}
</style>
