var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('div',{staticClass:"center-box",style:({ height: ("calc(100% - " + _vm.paddingBottom + ")") })},[_c('vuedraggable',_vm._b({on:{"end":_vm.widgetDragEnd},model:{value:(_vm.pageData.widgets),callback:function ($$v) {_vm.$set(_vm.pageData, "widgets", $$v)},expression:"pageData.widgets"}},'vuedraggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition"}},_vm._l((_vm.pageData.widgets),function(item,index){return _c('div',{key:'data' + index,on:{"click":function($event){return _vm.widgetClick(item, index)}}},[_c('widget',{class:index == _vm.opt.currentWidget ? 'widget-choosed' : '',style:(item.type == 'bottom-wx' ||
              item.type == 'wx' ||
              item.type == 'wxImgs' ||
              item.type == 'gzhImgs' ||
              item.type == 'long-copy' ||
              item.type == 'click-copy'
                ? 'transform: none;position:unset;'
                : ''),attrs:{"id":'data' + index,"w-data":item,"opt":_vm.opt,"data-clipboard-text":item.props && item.props.wechatNumber},on:{"copyBtn":function($event){return _vm.copyBtn(index)}}})],1)}),0)],1)],1),(_vm.getBottom())?_c('div',{on:{"click":function($event){return _vm.widgetClick(_vm.bottomBox, _vm.bottomIndex)}}},[_c('wxVCard',{ref:"bottomBox",class:_vm.opt.currentWidget == _vm.bottomIndex ? 'widget-choosed' : '',staticStyle:{"width":"100%","transform":"none"},attrs:{"id":"dataBottom","data-clipboard-text":_vm.bottomBox.props && _vm.bottomBox.props.wechatNumber,"w-data":_vm.bottomBox,"opt":_vm.opt},on:{"copyBtn":function($event){return _vm.copyBtn('Bottom')}}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }