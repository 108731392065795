<template>
  <div class="text—setting">
    <el-form>
      <h2>
        <i class="vertcial-strip"></i>
        文本内容设置
      </h2>
      <el-form-item v-if="wData.type == 'text'" label="文本内容："
        ><el-input v-model="wData.props.textPre" type="textarea"></el-input
      ></el-form-item>
      <el-form-item v-if="wData.type == 'text-wx'" label="文本内容(前)："
        ><el-input v-model="wData.props.textPre" type="textarea"></el-input
      ></el-form-item>
      <el-form-item v-if="wData.type == 'text-wx'" label="微信号："
        ><el-input v-model="wData.props.wechatNumber" type="textarea"></el-input
      ></el-form-item>
      <el-form-item v-if="wData.type == 'text-wx'" label="文本内容(后)："
        ><el-input v-model="wData.props.textSuf" type="textarea"></el-input
      ></el-form-item>
      <el-form-item>
        <div class="inine-set">
          字体大小：
          <el-select
            v-model="wData.props.font"
            placeholder="字体大小"
            style="width: 150px"
          >
            <el-option
              v-for="(item, key) in fontSizeMap"
              :key="key"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <div class="inine-set">
          <span class="choice-color">
            字体颜色：
            <el-color-picker v-model="wData.props.color"></el-color-picker>
          </span>
        </div>
      </el-form-item>
      <h2>
        <i class="vertcial-strip"></i>
        布局设置
      </h2>
      <el-form-item>
        <div class="input-box-title">位置</div>
        <div class="inine-set">
          横轴：
          <el-input-number
            v-model="wData.props.horizontalAxis"
            controls-position="right"
            :precision="2"
          ></el-input-number>
        </div>
        <div class="inine-set">
          纵轴：
          <el-input-number
            v-model="wData.props.longitudinalAxis"
            controls-position="right"
            :precision="2"
          ></el-input-number>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="input-box-title">尺寸</div>
        <div class="inine-set">
          宽度：
          <el-input-number
            v-model="wData.props.width"
            controls-position="right"
            :precision="2"
          ></el-input-number>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "VWeixinSetting",
  props: {
    wData: {
      type: Object,
      default() {
        return {
          id: "",
          name: "未命名",
          type: "text",
          props: {
            textPre: "默认文本文字",
            wechatNumber: "",
            textSuf: "",
            font: "16px",
            color: "",
            horizontalAxis: "",
            longitudinalAxis: "",
            width: 320,
          },
        };
      },
    },
  },
  data() {
    return {
      left: null,
      fontSizeMap: ["14px", "16px", "18px", "1rem", "2rem", "2.5rem"],
    };
  },
  methods: {
    changeSize(val, oldVal) {
      console.log(val);
    },
    recover() {
      console.log("recover");
    },
    delComponent() {
      console.log("delComponent");
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 1.2rem;
}
.el-input,
.el-textarea {
  width: 70%;
}
.text—setting /deep/ .el-form-item__label {
  width: 115px;
  color: #000;
}
.inine-set {
  /* display: inline-block; */
  padding: 5px 10px;
  box-sizing: border-box;
  margin-right: 1.3rem;
}
.el-color-picker {
  position: relative;
  top: 0.9rem;
  left: 1rem;
}
.vertcial-strip {
  display: inline-block;
  width: 5px;
  height: 20px;
  background-color: rgb(78, 144, 255);
  border-radius: 2rem;
  margin-right: 1rem;
  position: relative;
  top: 3px;
}
.input-box-title {
  position: relative;
  padding: 3px 12px;
  display: inline-block;
  font-weight: bold;
}
</style>
