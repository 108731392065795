<template>
  <div class="drawbox">
    <el-drawer
      :visible.sync="dialog"
      :direction="direction"
      :show-close="false"
      :with-header="false"
      :before-close="handleClose"
    >
      <div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="图片选择" name="1">
            <div class="photoList">
              <div
                v-for="(item, index) in pictureList"
                :key="index"
                :class="
                  clickActive === index ||
                  (wData.type === 'picture-carousel' &&
                    wData.props.photoList.findIndex(
                      (i) => i.rotationId == item.id
                    ) > -1)
                    ? 'photoList_item photoList_item_active'
                    : 'photoList_item'
                "
                @click="changeActive(item, index)"
              >
                <img :src="item.photoUrl" alt="" />
                <i
                  class="el-icon-error isdelete"
                  @click="deleteThis(item.id)"
                ></i>
                <span
                  :class="
                    clickActive === index
                      ? 'isselect isselect_active'
                      : 'isselect'
                  "
                >
                  <i class="el-icon-check"></i>
                </span>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="图片上传" name="2">
            <div class="setsty">
              <el-upload
                class="upload-demo uploadsty"
                action="#"
                :show-file-list="false"
                :http-request="upload"
              >
                <el-image
                  v-if="dictAddForm.photoUrl"
                  :src="dictAddForm.photoUrl"
                  class="upload-img"
                  @click="deleteThisImg"
                >
                </el-image>
                <!--   <i class="el-icon-upload" v-else></i> -->
                <div v-else>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    将文件拖到此处，或<em>点击上传</em>
                  </div>
                </div>
              </el-upload>
            </div>
            <div class="uploadBtn">
              <el-button
                v-if="dictAddForm.photoUrl"
                type="primary"
                plain
                @click="addpic"
                >上传</el-button
              >
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-drawer>
  </div>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    wData: {
      type: Object,
      default() {
        return {
          id: "",
          name: "",
          type: "picture-add",
          props: {
            /*  photoUrl: "",
            horizontalAxis: "",
            longitudinalAxis: "",
            height: "",
            width: "", */
          },
        };
      },
    },
    openid: {
      default: "",
    },
  },
  data() {
    return {
      activeName: "1",
      direction: "rtl",
      dictAddForm: {
        photoUrl: "",
        photoName: "",
      },
      pictureList: [], //图片列表
      clickActive: -1, //选单张图片
      activeList: [], //选多张图片
    };
  },
  mounted() {
    this.getPictureList();
    console.log("初始", this.wData);
  },
  methods: {
    //关闭弹窗
    handleClose() {
      this.$emit("closeDialog");
    },
    //获取图片列表
    getPictureList() {
      this.$http
        .get("/function/photo/getTemplatePhotoList")
        .then((response) => {
          if (response.data.code === 200) {
            this.pictureList = response.data.data;
            /*  this.$message({
                message: "保存成功",
                type: "success",
                duration: 2000,
              }); */
          }
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    //tab切换
    handleClick(tab) {
      this.activeName = tab.name;
      this.dictAddForm.photoUrl = "";
    },
    //图片上传
    upload(params) {
      let file = params.file;
      const isFormat =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif";
      const isSize = file.size / 1024 / 1024 < 30;
      if (!isFormat) {
        this.$message.error("上传图片只能是jpg、png、gif格式");
        return;
      }
      if (!isSize) {
        this.$message.error("上传图片大小不能超过30MB");
        return;
      }
      let form = new FormData();
      form.append("fileType", "1");
      form.append("files", file);
      console.log("//", params);
      this.$http.post("/common/file/upload", form).then((response) => {
        if (response.data.code === 200) {
          let data = response.data.data;
          if (data) {
            this.dictAddForm.photoUrl = data[0].url;
            this.dictAddForm.photoName = data[0].name;
            this.$message({
              message: "添加图片成功",
              type: "success",
              duration: 2000,
            });
          }
        }
      });
    },
    //添加图片
    addpic() {
      if (!this.dictAddForm.photoUrl) return;
      this.$http
        .post("/function/photo/add", this.dictAddForm)
        .then((response) => {
          // .then((response) => {
          if (response.data.code === 200) {
            this.$message.success("添加成功");
            this.getPictureList();
          }
        });
    },
    //删除图片
    deleteThis(id) {
      this.$confirm("是否确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http
          .delete("/function/photo/delete?id=" + id)
          .then((response) => {
            // .then((response) => {
            if (response.data.code === 200) {
              this.$message.success("删除成功");
              this.getPictureList();
            }
          });
      });
    },
    deleteThisImg() {
      this.dictAddForm.photoUrl = "";
    },
    //选择图片
    changeActive(item, index) {
      this.clickActive = index;
      //多张选择
      if (this.$props.wData.type === "picture-carousel") {
        if (this.$props.wData.props.photoList.length > 4) {
          this.$message.error("轮播图不能超过5条");
          return;
        }

        if (
          this.$props.wData.props.photoList.findIndex(
            (i) => i.rotationId == item.id
          ) == -1
        ) {
          if (
            this.$props.wData.props.photoList.findIndex(
              (i) => i.rotationId == -1
            ) > -1
          ) {
            let index = this.$props.wData.props.photoList.findIndex(
              (i) => i.rotationId == -1
            );
            this.$props.wData.props.photoList.splice(index, 1);
          }
          let obj = {
            rotationId: item.id,
            url: item.photoUrl,
            label: "",
            link: "",
            sort: index,
          };
          this.$props.wData.props.photoList.unshift(obj);
        }
      } else if (this.$props.wData.type === "guide-official") {
        switch (this.$props.openid) {
          case 1:
            this.$props.wData.props.photoUrl = item.photoUrl;
            break;
          case 2:
            this.$props.wData.props.photoUrl2 = item.photoUrl;
          case 3:
            this.$props.wData.props.photoUrl3 = item.photoUrl;
        }
      } else if (this.$props.wData.type === "wx") {
        this.$props.wData.props.wechatPictureUrl = item.photoUrl;
      } else {
        //单张选择
        this.$props.wData.props.photoUrl = item.photoUrl;
      }
    },
  },
};
</script>
<style>
.rtl {
  padding: 10px;
}
.upload-demo-picture {
  text-align: center;
}
.setsty {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 360px;
  height: 180px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.uploadBtn {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}
.photoList {
  display: flex;
  flex-direction: column;
}
.photoList_item {
  padding: 5px;
  border: 1px solid #e5e5e5;
  position: relative;
  width: 240px;
  height: 240px;
  margin-bottom: 20px;
}
.photoList_item_active {
  border: 1px solid #4e90ff;
}

.photoList_item > img {
  width: 100%;
  height: 100%;
}
.photoList_item:hover .isdelete {
  display: inline-block;
}
.isdelete {
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
  cursor: pointer;
}
.isselect {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #4e90ff;
  padding: 2px;
  box-sizing: border-box;
  position: absolute;
  bottom: 5px;
  right: 5px;
  opacity: 0;
}
.isselect_active {
  opacity: 1;
}
.isselect > i {
  color: #ffffff;
}
.uploadsty {
  width: 100%;
  height: 100%;
}
.uploadsty > div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
