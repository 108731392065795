<template>
  <div class="page-box">
    <v-page-preview
      :opt="opt"
      :page-data="siteData.pages[opt.currentPage]"
    ></v-page-preview>
  </div>
</template>

<script>
import PagePreview from "./pagePreview.vue";

export default {
  components: {
    "v-page-preview": PagePreview,
  },
  props: {
    opt: {
      type: Object,
      default() {
        return {
          isEditPreview: false,
          currentPage: 0,
          currentWidget: 0,
        };
      },
    },
    siteData: {
      type: Object,
      default() {
        return {
          id: "",
          name: "",
          mainPage: 0,
          pages: [],
        };
      },
    },
  },
  data() {
    return {};
  },

  created() {
    // 非预览编辑模式
    // console.log(this.siteData, "this.siteData ");
    if (!this.opt.isEditPreview) {
      // this.siteData = window.siteData;
    }
  },
};
</script>

<style scoped>
.page-box {
  width: 100%;
  margin: 0rem auto;
}
</style>
