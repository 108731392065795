<template>
  <div class="warp-content">
    <el-form>
      <h2><i class="vertcial-strip"></i>组件样式设置</h2>
      <el-form-item>
        <div class="flex-box">
          <span class="choice-color">
            背景颜色：
            <el-color-picker
              v-model="wData.props.assemblyBackgroundColor"
            ></el-color-picker>
          </span>
          <span class="choice-color">
            字体颜色：
            <el-color-picker
              v-model="wData.props.assemblyFontColor"
            ></el-color-picker>
          </span>
        </div>
        <div class="inine-set">
          字体设置：
          <el-select
            v-model="wData.props.assemblyFont"
            placeholder="字体大小"
            style="width: 150px"
          >
            <el-option
              v-for="(item, key) in fontSizeMap"
              :key="key"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <div v-if="wData.type == 'bottom-long-copy'" class="inine-set">
          组件文字：
          <el-input
            v-model="wData.props.assemblyContent"
            placeholder="请输入文字"
          ></el-input>
        </div>
      </el-form-item>
      <h2><i class="vertcial-strip"></i>关注按钮设置</h2>
      <el-form-item>
        <div class="flex-box">
          <span class="choice-color">
            按钮背景颜色：
            <el-color-picker
              v-model="wData.props.followButtonBackgroundColor"
            ></el-color-picker>
          </span>
          <span class="choice-color">
            按钮字体颜色：
            <el-color-picker
              v-model="wData.props.followButtonFontColor"
            ></el-color-picker>
          </span>
        </div>
        <div class="inine-set">
          字体设置：
          <el-select
            v-model="wData.props.followButtonFont"
            placeholder="字体大小"
            style="width: 150px"
          >
            <el-option
              v-for="(item, key) in fontSizeMap"
              :key="key"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <div class="inine-set">
          按钮文字：
          <el-input
            v-model="wData.props.followButtonContent"
            placeholder="请输入文字"
          ></el-input>
        </div>
        <div class="inine-set">
          按钮圆角：
          <el-slider v-model="wData.props.followButtonFillet"></el-slider>
        </div>
        <div class="flex-box">
          <span class="choice-color">
            高度：
            <el-input-number
              v-model="wData.props.followButtonHeight"
              controls-position="right"
              :precision="2"
            ></el-input-number>
          </span>
          <span class="choice-color">
            宽度：
            <el-input-number
              v-model="wData.props.followButtonWidth"
              controls-position="right"
              :precision="2"
            ></el-input-number>
          </span>
        </div>
      </el-form-item>
      <h2><i class="vertcial-strip"></i>微信内容设置</h2>
      <el-form-item>
        <div class="inine-set">
          微信号码：
          <el-input v-model="wData.props.wechatNumber"></el-input>
        </div>
      </el-form-item>
      <h2><i class="vertcial-strip"></i>微信号码设置</h2>
      <el-form-item>
        <div class="inine-set">
          微信账号字体设置：
          <el-select
            v-model="wData.props.wechatNumberFont"
            placeholder="字体大小"
            style="width: 150px"
          >
            <el-option
              v-for="(item, key) in fontSizeMap"
              :key="key"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <div class="inine-set">
          微信账号字体颜色：
          <el-color-picker
            v-model="wData.props.wechatNumberColor"
          ></el-color-picker>
        </div>
      </el-form-item>
      <h2><i class="vertcial-strip"></i>头像设置</h2>
      <el-form-item>
        <div class="inine-set">
          <span
            style="color: rgb(78, 144, 255); cursor: pointer; font-size: 16px"
            @click="openDialog"
            >+ 添加图片</span
          >
        </div>
        <div class="flex-box">
          <span class="choice-color">
            高度：
            <el-input-number
              v-model="wData.props.wechatPictureHeight"
              controls-position="right"
              :precision="2"
            ></el-input-number>
          </span>
          <span class="choice-color">
            宽度：
            <el-input-number
              v-model="wData.props.wechatPictureWidth"
              controls-position="right"
              :precision="2"
            ></el-input-number>
          </span>
        </div>
      </el-form-item>
      <h2><i class="vertcial-strip"></i>布局设置</h2>
      <el-form-item>
        <div class="inine-set">
          高度(不推荐)：
          <el-input-number
            v-model="wData.props.assemblyHeight"
            controls-position="right"
            :precision="2"
          ></el-input-number>
        </div>
      </el-form-item>
      <h2>
        <i class="vertcial-strip"></i>
        弹窗类型设置
      </h2>
      <el-form-item>
        <el-select
          v-model="wData.props.popupType"
          placeholder="请选择"
          style="width: 200px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <h2 style="margin-bottom: 10px">
        <i class="vertcial-strip"></i>
        是否展示下方手指
      </h2>
      <el-form-item>
        <el-switch
          v-model="wData.props.finger"
          style="padding-left: 10px"
          active-color="#13ce66"
          inactive-color="#ff4949"
        ></el-switch>
      </el-form-item>
    </el-form>
    <drawerDialog
      :dialog="dialog"
      :w-data="wData"
      @openDialog="openDialog"
      @closeDialog="closeDialog"
    />
  </div>
</template>

<script>
import drawerDialog from "@/components/drawerDialog";
import popupConfig from "../popupConfig.js";
export default {
  components: { drawerDialog },
  props: {
    wData: {
      type: Object,
      default() {
        return {
          id: "",
          name: "未命名",
          type: "wxCopy",
          props: {
            assemblyBackgroundColor: "#ffffff",
            assemblyFontColor: "",
            assemblyHeight: "",
            assemblyFont: "14px",
            assemblyContent: "关注微信号",
            followButtonBackgroundColor: "#13ce66",
            followButtonFontColor: "#ffffff",
            followButtonFont: "14px",
            followButtonContent: "关注",
            followButtonFillet: 20,
            followButtonHeight: "25",
            followButtonWidth: "50",
            wechatNumber: "weixin帐号",
            wechatNumberFont: "",
            wechatNumberColor: "",
            wechatPictureUrl:
              "http://240ps.com/jc/Dfile/20160907/j1609072_2.jpg",
            wechatPictureHeight: "20",
            wechatPictureWidth: "20",
            popupType: "",
            finger: "",
          },
        };
      },
    },
  },
  data() {
    return {
      fontSizeMap: ["14px", "16px", "18px", "1rem", "2rem", "2.5rem"],
      options: [...popupConfig],
      dialog: false,
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 1.2rem;
}
.el-input,
.el-textarea {
  width: 80%;
}
.inine-set {
  padding: 10px;
  box-sizing: border-box;
  margin-right: 1.3rem;
}
.el-color-picker {
  position: relative;
  top: 0.9rem;
  left: 1rem;
}
.vertcial-strip {
  display: inline-block;
  width: 5px;
  height: 20px;
  background-color: rgb(78, 144, 255);
  border-radius: 2rem;
  margin-right: 1rem;
  position: relative;
  top: 3px;
}
.warp-content /deep/ .el-form-item__label {
  color: #000;
  text-indent: 10px;
}
.input-box-title {
  position: relative;
  padding: 3px 12px;
  display: inline-block;
  font-weight: bold;
}
.vertcial-strip {
  display: inline-block;
  width: 5px;
  height: 20px;
  background-color: rgb(78, 144, 255);
  border-radius: 2rem;
  margin-right: 1rem;
  position: relative;
  top: 3px;
}

.input-box-title {
  position: relative;
  padding: 3px 12px;
  display: inline-block;
}

.choice-color {
  flex-direction: column;
  width: 33%;
  /* padding: 1rem 1.2rem; */
  padding: 5px 10px;
}

.number-input {
  margin: 0 5px;
}
</style>
